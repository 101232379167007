@font-face {
  font-family: "Poppins";
  src: url("./static/fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

html {
  font-family: "Poppins", sans-serif;
  background-color: #121212 !important;
}
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.MuiTypography-root {
  font-family: "Poppins", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.text-center {
  text-align: center;
}

.p {
  padding: 0rem 4rem 0rem 4rem;
}

.registerMargin {
  margin-top: 2rem !important;
}

.sigCanvas {
  background-color: #fff;
}

.react-calendar button {
  background-color: #181818;
  color: #fff;
  cursor: pointer;
  border: none;
}
.react-calendar__navigation{
  width: 40%;
  margin: auto;

}
.react-calendar__month-view__weekdays__weekday{
  text-align: center;
  color: #fff;
}
.react-calendar__month-view__weekdays__weekday abbr{
  text-decoration: none;

}
.react-calendar__month-view__days__day{
  font-size: 1rem;
}